@import 'src/styles/config';

.header {
  box-shadow: 0 0.1rem 0.1rem 0 rgba(#1c162c, 0.1);
  position: sticky;
  top: 0;
  z-index: 99;
  background: #fff;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  height: $header-height;
}

.data {
  display: grid;
  grid-gap: 0.2rem;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  margin-right: -1.6rem;
}

.messageBadge {
  background: #e55c1a;
}
