@import "src/styles/config";

.dialogText {
  h1 {
    font-size: 3.2rem;
  }
  h2 {
    font-size: 2.8rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .dialogText {
    h1 {
      font-size: 2.6rem;
    }
    h2 {
      font-size: 2.2rem;
    }
  }
}
