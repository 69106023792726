@import 'src/styles/config';

.wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.7rem 2.4rem;
  flex: 0 0 auto;
}
.title {
  padding: 0.6rem 0 0.6rem;
  align-items: center;
  display: flex;
  flex-grow: 1;
}
.icon {
  color: #36587a;
  height: 2.8rem;
  width: 2.8rem;
  flex: 0 0 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 0 0;
}
.close {
  margin: 0 -0.8rem 0 1rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .wrap {
    padding: 0.8rem 1.6rem;
  }
  .title {
    font-size: 1.4rem;
  }
}
