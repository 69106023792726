@import 'src/styles/config';

.wrap {
  :global {
    .MuiSvgIcon-colorDisabled {
      color: #cfdce7;
    }
  }
}
.button {
  opacity: 0;
  padding: 2px;
  margin-right: -2px;
}
:global {
  .MuiFormControl-root {
    &:hover {
      :local {
        .button {
          opacity: 0.6;
        }
      }
    }
  }
}
