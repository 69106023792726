@import 'src/styles/config';
.root {
  overflow: auto;
  position: relative;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.6rem;
    background-color: transparent;
    border-radius: 0.6rem;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.7rem;
    background-color: transparent;
  }

  &:hover,
  &.visible {
    &::-webkit-scrollbar {
      background-color: rgba(gray, 0.1);
    }
    &::-webkit-scrollbar-track {
      background-color: rgba($color-grey, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-secondary;
      &:hover {
        background-color: $color-secondary;
      }
    }
  }
}
