.box {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
  font-size: 1.2rem;
  line-height: 1.7;
  color: rgba(#000, 0.44);
  &:first-child {
    margin-top: 0;
  }
  div {
    background: #f3f6f9;
    border-radius: 2rem;
    font-weight: bold;
    padding: 0 1rem;
    text-align: center;
    min-width: 11rem;
  }
}
