@import 'src/styles/config';

.search {
  .input {
    &::placeholder {
      opacity: 0.75;
    }
  }
  :global {
    .MuiOutlinedInput-root {

      fieldset {
        border-radius: 1.2rem;
      }

      &:hover {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.25);
          }
        }
      }

      &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: $color-secondary;
      }
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -0.4rem -0.2rem 0;
}

.chip {
  margin: 0.4rem 0.2rem 0;
  min-height: 3.6rem;
  border-radius: 1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  border-color: rgba(0, 0, 0, 0.1);
  &Label {
    font-size: 1.4rem;
  }
}
.iconAdd {
  color: #cfdce7 !important;
}
