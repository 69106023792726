.item {
  display: flex;
  margin: 0 0 0.5rem;
}

.reitem {
  justify-content: flex-end;

  .box {
    flex-direction: row-reverse;
  }

  .avatar {
    margin: 0 1rem 0 0;
  }

  .cont {
    background: #cfdce7;
    border-radius: 1.6rem 0 1.6rem 1.6rem;
  }
}

.box {
  display: inline-flex;
  align-items: flex-start;
}

.avatar {
  flex-shrink: 0;
  margin: 0 1rem 0 0;
}

.content {
  background: #f3f6f9;
  border-radius: 0 1.6rem 1.6rem 1.6rem;
  padding: 1rem 1.5rem 0.5rem;
  color: #000;
  font-size: 1.3rem;
  line-height: 1.6;

  p {
    margin: 0;
  }
}

.time {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
}
