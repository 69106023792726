@import 'src/styles/config';

.wrap {
  align-items: center;
  display: flex;
  margin-left: -0.5rem;
  width: calc(100% + 0.5rem);
}
.name {
  flex-grow: 1;
}

.icon {
  flex: 0 0 3rem;
  color: #cfdce7;
}
