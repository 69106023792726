@import "src/styles/config";

.badge {
  border-radius: 50%;
}

.avatar {
  color: rgba($color-secondary, .4);
  background: rgba(227, 224, 227, 0.2);
  box-sizing: content-box;

  .image {
    border-radius: 50%;
  }
}
.clickable {
  cursor: pointer;
}
.size{
  &-xs{
    width: 2.8rem;
    height: 2.8rem;
  }
  &-sm{
    width: 3.6rem;
    height: 3.6rem;
  }
  &-md{
    width: 4rem;
    height: 4rem;
  }
  &-xm {
    width: 6rem;
    height: 6rem;
  }
  &-lg{
    width: 8rem;
    height: 8rem;
  }
  &-xl{
    width: 16rem;
    height: 16rem;
  }
}
