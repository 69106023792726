@import 'src/styles/config';

html {
  background-color: white !important;
  -moz-osx-font-smoothing: unset !important;
  -webkit-font-smoothing: unset !important;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 10px;
}

body {
  position: relative;
  font: 1.3rem / 1.43 'Arimo', Arial, Tahoma, Geneva, sans-serif;
  letter-spacing: normal !important;
  min-height: calc(var(--vh, 1vh) * 100);
  color: rgba(#000, 0.87);
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration-skip-ink: none;
  color: $color-secondary;
  text-decoration: none;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 120.2rem;
  position: relative;
  padding: 0 $base-padding-mobile;
  @extend %margin-last;
}

// inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input,
textarea {
  &::placeholder {
    opacity: 1 !important;
    color: $color-grey !important;
  }
}
