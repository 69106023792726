@import 'src/styles/config';

.wrap {
  display: grid;
  grid-template-columns: 1fr 4.4rem;
  grid-template-areas: 'name avatar';
  align-items: center;
  grid-gap: 1rem 1.5rem;
  text-decoration: none;
  color: $color-secondary;
  line-height: 1.3;
  font-size: 1.3rem;
  max-width: 19rem;
  min-width: 14rem;
  padding: 0.3rem 1rem;
  border-radius: 0.3rem;
}

.name {
  grid-area: name;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.avatar {
  grid-area: avatar;
  position: relative;
  z-index: 1;
  padding: 0.2rem;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #36587a, #5d86b0);
    border-radius: 50%;
    z-index: -2;
  }

  &::after {
    top: 0.2rem;
    left: 0.2rem;
    right: 0.2rem;
    bottom: 0.2rem;
    background: #fff;
    z-index: -1;
  }
}

@media (min-width: map-get($breakpoints, 'md') + px) and (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .wrap {
    display: inline-grid;
    grid-template-columns: 1fr;
    min-width: auto;
    max-width: inherit;
    grid-template-areas: 'avatar';
    width: 6.4rem;
    border-radius: 50%;
    padding: 1rem;
  }
  .name {
    display: none;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .wrap {
    display: inline-grid;
    grid-template-columns: 4.4rem 1fr;
    grid-template-areas: 'avatar name';
    color: #fff;
    border-radius: 3rem;
    padding: 0.8rem 2.2rem 0.8rem 0.8rem;
    min-width: inherit;
    max-width: inherit;

    &:hover {
      background: none;
    }
  }
  .avatar {
    &::before {
      background: #fff !important;
    }
  }
}
