@import 'src/styles/config';

.wrap {
  background: #fff;
  box-shadow: 0 0.1rem 1.1rem 0 rgba(#000, 0.1);
  padding: 0.5rem 1.6rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  grid-gap: 1.6rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: $header-height-mobile;
  > * {
    min-width: 0;
  }
}

.data {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  grid-gap: 1.6rem;
}
.icon {
  height: 3rem;
  width: 3rem;
  min-height: 3rem;
  font-size: 2rem;
  box-shadow: none;
  background: rgba(54, 88, 122, 0.06);
  &:active {
    box-shadow: none;
    background: rgba(54, 88, 122, 0.06);
  }
}
.menu {
  margin: 0 -1.3rem;
}
