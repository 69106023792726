@import 'src/styles/config';

.wrap {
  display: grid;
  grid-template-areas:
    'header header'
    'dialogs messages';
  grid-template-rows: auto 1fr;
  grid-template-columns: 30rem 48rem;
  height: 100%;
}
.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(#546e7b, 0.1);
  padding: 0.5rem 1rem 0.5rem 1rem;
  min-height: 6.5rem;
}
.title {
  font-size: 2.4rem;
  color: $color-secondary;
}
.dialogs {
  grid-area: dialogs;
  padding: 3.2rem 1.6rem 1.6rem;
  background-color: white;
  border-right: 1px solid rgba(#546e7b, 0.1);
}
.messages {
  grid-area: messages;
  padding: 0.8rem 0.4rem 1.2rem;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .wrap {
    grid-template-areas:
      'header'
      'dialogs';
    grid-template-columns: 1fr;
    width: 32rem;
  }
}
