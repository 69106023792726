@import 'src/styles/config';

.wrap {
  position: fixed;
  bottom: 1.6rem;
  left: 1.6rem;
  z-index: 99;
  button {
    border-radius: 0.6rem;
  }
}
.tooltip {
  font-size: 1.2rem;
  border-radius: 1.2rem;
  background: #fff;
  box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  padding: 0.7rem 1.2rem;
}
.badge {
  display: inline-flex;
  color: white;
  background-color: #e55c1a;
  padding: 0.1rem 0.5rem;
  border-radius: 50%;
}
.m {
  &Tooltip {
    background-color: transparent;
    box-shadow: none;
    padding: 0 1rem;
  }
  &Arrow {
    color: white;
  }
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .wrap {
    left: 0;
    button {
      border-radius: 0.6rem 0 0 0.6rem;
    }
  }
}
