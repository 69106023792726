@import 'src/styles/config';

.wrap {
  background: #eaeef2;
  padding: 2.5rem 0 0.4rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-end;
  align-items: baseline;
  grid-gap: 0 1.1rem;
}

.copy {
  color: $color-grey;
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .wrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
