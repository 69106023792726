@import 'src/styles/config';

.header {
  flex-shrink: 0;
  padding: 0.8rem 1.6rem 1.6rem 0.4rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 1.6rem;
    right: 1.6rem;
    height: 1px;
    background: rgba(#546e7b, 0.1);
  }
}

.user {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  font-size: 1.1rem;
  border-radius: 2.5rem;
  padding: 0.4rem 0.8rem 0.4rem 1.6rem;
  margin: -0.4rem -0.8rem -0.4rem 0;
  width: auto;
}

.name {
  font-size: 1.4rem;
  font-weight: bold;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .header {
    padding-left: 0;
    &::before {
      left: 0;
      right: 0;
    }
  }
}
