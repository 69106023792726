@import 'src/styles/config';

.box {
  display: flex;
  flex-direction: column;
  > * {
    width: 100%;
  }
}
.header {
  padding: 1.6rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  display: grid;
  grid-template-columns: repeat(2, auto);
  text-transform: uppercase;
  grid-gap: 1rem;
  align-items: center;
}

.close {
  margin: -1.2rem;
}
.body {
  padding: 0 1.6rem;
  flex-grow: 1;
}
.footer {
  padding: 1.6rem;
  text-align: left;
}
