@import "src/styles/config";


.wrap {
  width: 100%;
  display: grid;
  grid-template-rows: 13rem 10fr auto;
}

.header {
  background: $color-secondary url("../../../../images/bg-menu-mobile.png") no-repeat 50% 50%;
  background-size: cover !important;
  color: #fff;
  padding: 0.4rem 0 0.5rem 1.6rem;
  display: grid;
  grid-template-rows: auto 1fr;
  &Top {
    display: grid;
    grid-template-columns: 7.6rem auto ;
    justify-content: space-between;
    align-items: center;
  }
  &Bottom {
    align-self: end;
    margin: 0 0 0 -1rem;
    padding-bottom: 1rem;
  }
}

.footer {
  padding: 1.5rem 1.6rem;
  font-size: 1.2rem;
}
.nav {
  display: flex;
  margin: 0 0 1rem;
}
.navSep {
  margin:  0 0.8rem;
}

.copy {
  color: rgba(#000, 0.32);
}
