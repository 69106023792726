@import "src/styles/config";

.message {
  width: 100%;
}

.list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.chipLink {
  color: white;
}
