@import "src/styles/config";

.item {
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  color: $color-secondary;
  font-size: 1.4rem;
  padding: 1.1rem 1.6rem;
  position: relative;
  border-bottom: 1px solid rgba(#000, 0.1);
  &:last-child {
    border-bottom: 0;
  }
}
.icon {
  color: #CFDCE7;
  line-height: 0;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.6rem;
  min-width: 1.6rem;
  background: #E42C2C;
  border-radius: 0.8rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 0.5rem;
}

.current {
  color: $color-primary;
  .icon {
    color: $color-primary;
  }
}



