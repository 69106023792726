@import 'src/styles/config';

.main {
  flex-grow: 1;
  background: #eaeef2;
  display: flex;
  flex-direction: column;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .main {
    padding: $header-height-mobile 0 6.2rem;
  }
}
