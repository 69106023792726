@import "src/styles/config";

.logo {
  display: block;
  font-size: 1.3rem;
  color: #36587a;
  white-space: nowrap;
  line-height: 1;
  font-weight: normal;
  text-decoration: none;
  padding-top: 0.2rem;
  :hover {
    cursor: pointer;
  }

  img {
    width: 13rem;
    max-width: 100%;
    display: block;
  }
}

@media (max-width: map-get($breakpoints, "md") - 1 + px) {
  .logo {
    font-size: 1rem;

    img {
      margin-bottom: 0.4rem;
      width: 8.5rem;
    }

    span {
      display: none;
    }
  }
}
