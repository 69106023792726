@import "src/styles/config";


.popover_title  {
  display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 1.6rem;
	flex: 0 0 auto;
  font-size: 1.6rem;
}
.close {
	margin: 0 -0.8rem 0 1rem ;
}
.popover_content {
  padding: 0 1.6rem;
}

.list {
  margin: 0 -1rem;
	padding: 0 0 0.6rem;
}
