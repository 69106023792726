@import 'src/styles/config';

.wrap {
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  & > * {
    min-width: 0;
  }
}
.content {
  padding: 1.6rem 0 0.8rem;
}
.hidden {
  overflow: hidden;
}
.footer {
  z-index: 99;
  padding: 0 1.6rem 1rem 1.6rem;
}
