$base-padding: 3rem;
$base-padding-mobile: 1.6rem;

$box-shadow: 0 0.2rem 1.2rem 0 rgba(#000, 0.1);

$border-radius: 3.2rem;

$max-width: 154rem;
$max-width-content: 117rem;

$header-height: 7.2rem;
$header-height-mobile: 5.2rem;

$RubikFont: 'Rubik', Arial, Tahoma, Geneva, sans-serif;

$box-shadow-mobile: 0 0.1rem 0.1rem 0 rgba(#000, 0.1), 0 0.1rem 0.4rem 0 rgba(#000, 0.1);

%margin-last {
  > *:last-child {
    margin-bottom: 0;
  }
}
