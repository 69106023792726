.wrap {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.panel {
  margin: 0 0 2.2rem;
}

.list {
  > * {
    margin-bottom: 1.2rem;
    &:last-child {
      margin-bottom: 1.6rem;
    }
  }
}
