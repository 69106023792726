@import "src/styles/config";

.wrap {
  padding: 0 2.4rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .wrap {
    padding: 0 1.6rem;
  }
}
