@import 'src/styles/config';

.wrap {
  background-color: white;
  width: 32rem;
  padding: 1.6rem 0;
  border-top: 4px solid $color-secondary;

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header {
  padding: 0 1.6rem;
  align-items: flex-start;
  margin-bottom: 0.9rem;

  &Sub {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-top: 0.3rem;
  }

  &Content {
    align-self: center;
  }
}

.content {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.8rem;
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.data {
  margin-bottom: 0.4rem;
}

.footer {
  padding-top: 0;
  padding-bottom: 0;
}

.button {
  font-size: 1.1rem;
  min-height: 2.8rem;
}
