$color-primary: #5fb800;
$color-primary-light: #6dd400;

$color-secondary: #36587a;
$color-secondary-light: #4f769c;

$color-orange: #e55c1a;
$color-orange-light: #ff681f;

$color-black-87: rgba(#000, 0.87);
$color-black-30: rgba(#000, 0.3);

$color-grey: #777;

$color-white: #fff;
