@import 'src/styles/config';

.user {
  border-radius: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 2.8rem auto;
  grid-gap: 1rem 1rem;
  align-items: start;
  &.small {
    font-size: 1rem;
    .name {
      font-size: 1.2rem;
    }
  }
  .name {
    font-size: 1.4rem;
  }
  p {
    margin: 0;
    color: $color-grey;
  }
}
