.wrap {
  width: 100%;
  padding-top: 1rem;
  border-top: 1px solid rgba(84, 110, 123, 0.1);

  :global {
    .jodit-toolbar {
      &__box {
        background-color: white !important;
        border-color: white !important;
      }
    }
    .jodit-container {
      border-color: white !important;
    }
  }
}
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}
