@import "src/styles/config";

.wrap {
  background: #fff;
  box-shadow: 0 0.4rem 1.1rem 0 rgba(#000, 0.2);
  padding: 0.5rem 1.6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  min-height: 5.6rem;
  > * {
    min-width: 0;
  }
}

.item {
  text-align: center;
  font-size: 1rem;
  color: rgba(#000, 0.54);

  &Current {
    color: $color-primary;

    .button {
      background: rgba($color-primary, 0.2);
    }
  }
}

.button {
  border-radius: 1.4rem;
  height: 2.8rem;
  padding: 0 0.8rem;
}

.badge {
  height: 1.2rem;
  min-width: 1.2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background: #E42C2C;
  font-size: 0.9rem;
  font-weight: 400;
  border: none;
}


