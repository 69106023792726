.wrap {
  padding: 0.8rem;
  border-radius: 2rem;
}

.user {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 4rem 1fr;
  width: 100%;
  position: relative;
}

.notRead {
  padding: 0 2rem 0 0;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 1.4rem;
    right: 0.8rem;
    height: 0.8rem;
    width: 0.8rem;
    background: #e55c1a;
    border-radius: 50%;
  }
}

.cont {
  align-self: center;
  margin: -0.4rem 0;
  text-transform: none;
  > *:last-child {
    margin-bottom: 0;
  }
}

.name {
  font-size: 1.4rem;
}

.message {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.54);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  p {
    margin: 0;
  }
}
